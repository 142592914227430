/** ------------------------------
    Carousel
    ------------------------------ */
.carousel {
    padding: 0 2rem;

    & .glide__slides {
        margin: 0;
        align-items: stretch;
        }

        & .glide__slide {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: .5rem;
            height: auto;
            border: 4px solid rgba(0, 0, 0, .1);
            background-color: #fff;

            & > img {
                width: 100%;
                max-height: 120px;

                @supports (object-fit: contain) {
                    object-fit: contain;
                    }
                }
            }

    & .glide__arrow {
        @mixin button-reset;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 3rem;
        height: 3rem;
        background: transparent;
        color: var(--brand-secondary);
        cursor: pointer;

        &:matches(:focus, :hover) {
            outline: 0;
            color: var(--brand-light);
            background: var(--brand-secondary);
        }

        & > svg {
            width: 100%;
            height: 100%;
            fill: currentcolor;
            }
        }

    & .glide__arrow--left {
        left: -2rem;
        }

    & .glide__arrow--right {
        right: -2rem;
        }

    }
