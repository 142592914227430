/** ------------------------------
    Quote
    ------------------------------ */
.quote,
blockquote {
    margin: 3rem auto;
    padding: 0 2.5rem;

    & > p {
        position: relative;
        line-height: 1.5;

        &:matches(:first-of-type::before, :last-of-type::after) {
            position: absolute;
            content: "";
            width: 1.5em;
            height: 1.5em;
            background: url('../../img/inline/quote.svg') no-repeat 50% 50%;
            background-sizze: contain;
            }

        &:first-of-type::before {
            text-indent: 2em;
            transform:  translateX(-150%) rotate(180deg);
            }

        &:last-of-type::after {
            transform: translateX(50%);
            }
        }

    & cite {
        font-style: normal;
        font-weight: 400;
        }
    }
