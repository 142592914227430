/** ------------------------------
    Header
    ------------------------------ */
.site-header {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);

    & a {
        text-decoration: none;
        }

    }

.site-header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    padding-top: 0;
}

.admin-bar .site-header.sticky {
    padding-top: 32px;
}

/*.site-header.sticky {
    animation: slideDown 0.35s ease-out;
}

.site-header.sticky .bg-brand-primary {
    display:none;
}*/

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

/** ------------------------------
    Announcement
    ------------------------------ */
.site-header__announcement {
    padding: .75em 0;
    background-color: var(--brand-secondary);
    color: #fff;
    font-size: .875rem;
    text-align: center;

    & a:hover {
        text-decoration: underline;
        }
    }


/** ------------------------------
    Top bar
    ------------------------------ */
.site-header__top-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    font-size: .875rem;
    }

    /* Account */
    .site-header__account {
        margin-left: 1rem;
        line-height: 2.5rem;
        }
        .site-header__account-nav {
            display: flex;

            & a {
                display: block;
                padding: 0 .5em;

                @media (width > 500px) {
                    padding: 0 1em;
                    }
                }

            & > li {
                position: relative;

                &:nth-child(odd) {
                    background-color: var(--brand-secondary);
                    color: #fff;
                    }

                &:nth-child(even) {
                    background-color: #fff;
                    color: var(--brand-primary);
                    }

                &:hover {
                    background-color: var(--brand-light);
                    color: var(--brand-secondary);
                    }

                }
            }

    /* Social icons */
    .site-header__top-bar .social-list {

        & a:hover {
            color: var(--brand-secondary);
            }

        }

@media (width < 460px) {

    .site-header__top-bar {

        & .social-list-wrap__title {
            display: none;
            }

        }

}

@media (width >= 1080px) {

    .site-header__top-bar {
        @mixin container;
        @mixin container-gutters;
        }

}


/** ------------------------------
    Menu
    ------------------------------ */
.site-header__primary {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 6rem;
    line-height: 6rem;
    background-color: #fff;
    color: var(--brand-primary);
    }
    .site-header__brand-link {
        display: block;
        width: 50vw;
        max-width: 220px
        }

@media (height < 600px) {

    .site-header__primary {
        height: 5rem;
        line-height: 5rem;
        }

}

@media (width < 1080px) {

    .site-header__menu {
        display: none;
        position: absolute;
        top: 100%;
        flex: 1 0 100%;
        order: 9;
        margin: 0 calc(var(--container-gutter) / -2);
        width: 100%;
        background-color: var(--brand-primary);
        color: #fff;
        z-index: 9999;
        }
        .site-header__menu-item {
            line-height: 2;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, .2);
                }

            & :matches(a, span) {
                display: block;
                padding: .5em calc(var(--container-gutter) / 2);
                font-weight: 400;
                }

            }

        /* Subnav */
        .site-header__menu-subnav {

            & a {
                padding-left: 3em;
                }

            }

        /* Toggle */
        .site-header__menu-toggle {
            --size: 32px;

            margin-left: auto;
            width: var(--size);
            height: var(--size);

            /* Reset */
            padding: 0;
            border: 0;
            background: none;
            }

    /* Active state */
    .site-header__menu--active {
        display: block;
        }

}

@media (width >= 1080px) {

    .site-header__menu {
        display: flex;
        align-self: stretch;
        flex: 1;
        margin-left: 2.5vw;
        }
        .site-header__menu-nav {
            display: flex;
            justify-content: space-between;
            flex: 1;
            }
            .site-header__menu-item {
                position: relative;

                & > :matches(a, span) {
                    display: block;
                    font-weight: 400;
                    color: var(--brand-primary);
                    text-decoration: none;
                    transition: background-color ease-out .3s;

                    &:hover {
                        color: var(--brand-secondary);
                        }
                    }

                & > span {
                    cursor: default;
                    }

                /* Toggle subnav */
                &:matches(:hover, :focus-within) {

                    & .site-header__menu-subnav {
                        display: block;
                        }

                    }
                }

        /* Subnav */
        .site-header__menu-subnav {
            display: none;
            position: absolute;
            left: 0;
            top: 100%;
            margin-left: -1rem;
            min-width: 180px;
            border-bottom-right-radius: .15em;
            border-bottom-left-radius: .15em;
            background-color: #fff;
            box-shadow:
                0 2px 1.5px -12px rgba(0, 0, 0, 0.051),
                0 4.1px 3.7px -12px rgba(0, 0, 0, 0.073),
                0 6.7px 6.9px -12px rgba(0, 0, 0, 0.09),
                0 10.2px 12.3px -12px rgba(0, 0, 0, 0.107),
                0 16.4px 23px -12px rgba(0, 0, 0, 0.129),
                0 38px 55px -12px rgba(0, 0, 0, 0.18)
            ;
            line-height: calc(6rem / 2);
            white-space: nowrap;
            z-index: 500;

            & a {
                padding: 0 1rem;
                }
            }

        /* Align subnavs in the last two menu items to right, preventing an overflow */
        .site-header__menu-item:nth-last-child(-n+2) {

            & .site-header__menu-subnav {
                left: auto;
                right: 0;
                }

            }

        /* Remove toggle */
        .site-header__menu-toggle {
            display: none;
            }

}

/** ------------------------------
    Trustee Site Header
    ------------------------------ */
.trustee-template ul.site-header__menu-nav {
    justify-content: space-around;
}
/*.trustee-template ul.site-header__menu-nav li.site-header__menu-item {
    margin-right: 40px;
}*/
@media (width < 1080px) {
    .user-header-menu-links{display: none}
}

@media (width < 790px) {
    .admin-bar .site-header.sticky {
        padding-top: 45px;
    }
}
