/** ------------------------------
    Portal
    ------------------------------ */
.portal {
    }
    .portal__body {
        padding-top: 2rem;
        padding-bottom: 2rem;

        & > p:last-of-type {
            margin-bottom: 3rem;
            }
        }

@media (width > 700px) {

    .portal {
        position: relative;
        height: 60vh;
        min-height: 450px;
        max-height: 600px;
        }
        .portal__media {
            position: absolute;
            width: 50%;
            height: 100%;
            right: 0;

            & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                }
            }
        .portal__body {
            padding-top: 5rem;
            padding-bottom: 5rem;

            & > * {
                max-width: calc(50% - 10vw);
                }
            }

}
