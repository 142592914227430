/** ------------------------------
    Dashboard
    ------------------------------ */
@custom-media --dashboard-menu-toggle (width < 820px);
@custom-media --dashboard-menu-static (width >= 820px);

.dashboard {
    overflow: hidden;
    flex-grow: 1;

    @media (--dashboard-menu-static) {
        display: flex;
        }
    }
    .dashboard__sidebar {
        background-color: var(--brand-light);
        border-right: 2px solid var(--brand-mid);

        @media (--dashboard-menu-toggle) {
            position: fixed;
            z-index: 9999;
            top: 0;
            bottom: 0;
            overflow-y: auto;
            width: 80%;
            min-width: 280px;
            max-width: 320px;
            transform: translateX(-100%);
            transition: transform .2s ease;

                &.is-active {
                     transform: translateX(0);
                    &:focus-visible {
                         overflow-y: visible;
                     }
                }
            }

        @media (--dashboard-menu-static) {
            flex: 0 0 280px;
            }
        }
    .dashboard__body {
        padding: 2rem 0;
        flex-grow: 1;

        @media (--dashboard-menu-static) {
            padding-left: .75rem;
            overflow: auto;
        }
        .page-header:before{
            display: none;
        }
    }

.dashboard__sidebar-toggle {
    @mixin button;
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 1.25rem;
    background-color: var(--brand-secondary);
    box-shadow:
        0 0.5px 1px rgba(0, 0, 0, .028),
        0 1.6px 3.4px rgba(0, 0, 0, .042),
        0 7px 15px rgba(0, 0, 0, .07)
    ;
    color: #fff;
    font-size: 1.125rem;
    z-index: 9999;

    & svg {
        height: auto;
        }

    @media (--dashboard-menu-static) {
        display: none;
        }
    }


/** ------------------------------
    Menu
    ------------------------------ */
p.close-btn {
    position: absolute;
    top: 0px;
    right: 20px;
    cursor: pointer;
}
.dashboard__menu {
    padding: 2rem 1.5rem;
    .btn-cta {
    margin: 2rem 0 1rem;
    }
}
    .dashboard__nav {
        margin: 0 -1.5rem;

        & :matches(a, span) {
            display: block;
            padding: 1.5rem;
            color: var(--brand-primary);
            font-weight: 400;
            text-decoration: none;
            line-height: 1.4;

            &:hover {
                cursor: pointer;
                color: var(--brand-secondary);
                }
            }

        & .router-link-exact-active {
            position: relative;
            color: var(--brand-secondary);

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                right: -20px;
                width: 20px;
                height: 20px;
                background: var(--brand-light);

                border-right: 2px solid var(--brand-mid);
                border-bottom: 2px solid var(--brand-mid);
                transform: rotate(-45deg) translate3d(-2px, -50%, 0);
                }

            }

        }
        .dashboard__nav-title {
            margin-top: 2rem;
            margin-bottom: 0;
            padding: 0 1.5rem;
            font-size: .875rem;
            font-weight: 500;
            color: var(--brand-mid);
            text-transform: uppercase;
            letter-spacing: .05em;
            }
        .dashboard__nav-item {
            position: relative;
            &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, .04);
                }

            }


/** ------------------------------
    Widget grid
    ------------------------------ */
.dashboard__widget-grid {
    margin-top: 1.5rem;

    @media (width >= 1080px) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        /*grid-template-rows: minmax(400px, 1fr);*/
        gap: 1.5rem;

        & > * {
            margin: 0;
            }
        }
    }

.dashboard-widget__header {
    position: relative;
}
.dashboard__nav-item .tooltip, .dashboard-widget__header .tooltip {
    border: 1px solid;
    right: -100%;
    left: auto;
    top: 100%;
    z-index: 999999;
}

.dashboard__nav-item .tooltip:after, .dashboard-widget__header .tooltip:after {
    border-top: 10px solid #000;
    bottom: 30px;
    rotate: 90deg;
    left: -16px;
    z-index: 99999999;
}

.dashboard-widget.v-tour__target--highlighted {
    box-shadow: 0 0 0 4px rgb(0 0 0 / 40%) !important;
    pointer-events: auto;
    z-index: 9999;
    border-radius: 0 !important;
}
