/** ------------------------------
    Login/Register
    ------------------------------ */
.login {

    & .brand-rings {
        margin: 5rem 0;
        }

    & .panel {
        margin: auto;
        max-width: 480px;
        }

    }


/** ------------------------------
    Modifiers
    ------------------------------ */
.login--register {

    & .panel {
        max-width: 680px;
        }

    }


/** ------------------------------
    Login form
    ------------------------------ */

/* These styles hook in to the generate WP login form */
#loginform {

    & :matches(.login-username, .login-password, .login-rememer) {
        @mixin form-group;
        }

    & :matches([type="text"], [type="password"]) {
        @mixin form-control;
        }

    & [type="submit"] {
        @mixin button;
        width: 100%;
        height: calc(var(--btn-height) * 1.25);
        line-height: calc(var(--btn-height) * 1.25);
        }

    }


/** ------------------------------
    GovernorHub
    ------------------------------ */
.openid-connect-login-button {
    margin: 0 !important;

    & > a {
        @mixin button;
        display: block;
        height: calc(var(--btn-height) * 1.25);
        line-height: calc(var(--btn-height) * 1.25);
        background-color: #ffd665;
        color: #333;
        }

    & img {
        width: auto;
        height: 2em;
        }

    }
