/** ------------------------------
    Box sizing
    ------------------------------ */
html {
    box-sizing: border-box;
    }

*,
*::before,
*::after {
    box-sizing: inherit;
    }


/** ------------------------------
    Layout
    ------------------------------ */
body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
main {
    display: flex;
    flex-direction: column;
    /* Fill space, pushing the footer to the bottom */
    flex: 1 0 auto;
    /* Prevent the rings inside the .page-header component from leaking out */
    position: relative;
    overflow: hidden;
}

main.sticky {
    margin-top: 125px;
}

.site-announcement-active main.sticky {
    margin-top: 165px;
}

/** ------------------------------
    Containers
    ------------------------------ */
@define-mixin container {
    margin: auto;
    max-width: var(--container-width);
}

@define-mixin container-gutters {
    padding: 0 calc(var(--container-gutter) / 2);

    @media (width < 400px) {
        padding: 0 8px;
    }
}

.container {
    @mixin container;
    @mixin container-gutters;
    }

.container--md {
    max-width: calc(var(--container-width) * .8);
    }

.container--sm {
    max-width: calc(var(--container-width) * .6);
    }

.container--fluid {
    max-width: none;
    }

.is-contained {
    @mixin container;
    }
