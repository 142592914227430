/** ------------------------------
    Media block
    ------------------------------ */
.media-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @mixin grid-container;
    margin-top: -1.5rem;

    & > * {
        flex: 1 1 400px;
        @mixin grid-item;
        padding-top: 1.5rem;
        }
    }
    .media-block__media {
        text-align: center;
        }


/** ------------------------------
    Modifiers
    ------------------------------ */
.media-block--reversed {
    flex-direction: row-reverse;
    }
