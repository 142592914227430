/** ------------------------------
    FAQ
    ------------------------------ */
.faq {
    @mixin list-reset;
    }
    .faq__item {
        margin-bottom: 1rem;
        background-color: var(--brand-light);
        }
        .faq__item-title {
            position: relative;
            margin-bottom: 0;
            padding: 1rem 2rem;
            font-size: 1.25rem;
            font-weight: 400;
            cursor: pointer;

            &:matches(:hover, [aria-expanded="true"]) {
                color: var(--brand-secondary);
                }

            &::after {
                content: "";
                position: absolute;
                right: 1rem;
                top: 1.5rem;
                width: .75rem;
                height: .75rem;
                background: url('../../img/inline/down.svg') no-repeat 50% 50%;
                background-size: cover;
                }

            &[aria-expanded="true"]::after {
                transform: rotate(180deg);
                }
            }
        .faq__item-body {
            padding: 1rem 2rem 2rem;

            & :first-child {
                margin-top: 0 !important;
                }

            & :last-child {
                margin-bottom: 0 !important;
                }
            }
