/** ------------------------------
    Fonts
    ------------------------------ */
@font-face {
    font-family: 'stolzl';
    src: url('../resources/assets/fonts/Stolzl-Thin.woff2') format('woff2'),
         url('../resources/assets/fonts/Stolzl-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal
    }
@font-face {
    font-family: 'stolzl';
    src: url('../resources/assets/fonts/Stolzl-Light.woff2') format('woff2'),
         url('../resources/assets/fonts/Stolzl-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal
    }

@font-face {
    font-family: 'stolzl';
    src: url('../resources/assets/fonts/Stolzl-Book.woff2') format('woff2'),
         url('../resources/assets/fonts/Stolzl-Book.woff') format('woff');
    font-weight: 300;
    font-style: normal
    }

@font-face {
    font-family: 'stolzl';
    src: url('../resources/assets/fonts/Stolzl-Regular.woff2') format('woff2'),
         url('../resources/assets/fonts/Stolzl-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal
    }

@font-face {
    font-family: 'stolzl';
    src: url('../resources/assets/fonts/Stolzl-Medium.woff2') format('woff2'),
         url('../resources/assets/fonts/Stolzl-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal
    }

@font-face {
    font-family: 'stolzl';
    src: url('../resources/assets/fonts/Stolzl-Bold.woff2') format('woff2'),
         url('../resources/assets/fonts/Stolzl-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal
    }
