/** ------------------------------
    Footer
    ------------------------------ */
.site-footer {
    background-color: var(--brand-primary);
    color: #fff;
    font-size: .875rem;

    & a {
        text-decoration: none;

        &:hover {
            color: var(--brand-secondary);
            }
        }
    }
    .site-footer__grid {
        @mixin grid-container;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 3rem 0;
        }
        .site-footer__grid-item {
            @mixin grid-item;
            }

    /* Brand block */
    .site-footer__brand {

        & > svg {
            display: block;
            @mixin base-margin;
            width: 220px;
            }

        & > * {
            max-width: 52ch;

            @media (width > 1110px) {
                max-width: 32ch;
                }
            }

        }

    /* Menu */
    .site-footer__menu {
        margin-top: 1.5rem;
        line-height: 1.7;

        & .icon {
            margin-right: .25em;
            }
        }
            .site-footer__menu-title {
                margin: .5rem 0;
                }

    /* Legal */
    .site-footer__legal {
        padding: .5rem 0;

        & a {
            text-decoration: underline;

            &:hover {
                color: var(--brand-secondary);
                }
            }

        & > p {
            margin-top: 0;
            margin-bottom: .5em;
            }
        }

@media (width < 1110px) {

    .site-footer__grid-item {
        flex: 1 1 300px;

        &:first-child {
            flex-basis: 100%;
            }
        }

}
