/** ------------------------------
    Mailchimp Forms
    ------------------------------ */
#mc_embed_signup {
    margin: 30px auto;
    max-width: 600px;

    & .indicates-required {
        margin: 10px 0;
        text-align: right;
        }

    & p:empty {
        display: none;
        }

    & label {
        display: block;
        width: 100%;
        }

    & input {
        &:matches([type="text"], [type="email"]) {
            @mixin form-control;
            }

        &:matches([type="checkbox"], [type="radio"]) {
            margin-right: 5px;
            }

        &:matches([type="submit"]) {
            @mixin button;
            min-width: 50%;
            }
        }

    & .mc-field-group {
        margin-bottom: 20px;
        text-align: left;

        & br {
            display: none;
            }
        }

    & .input-group {
        & ul {
            @mixin list-reset;
            margin-top: 10px;
            }

        & label {
            display: inline;
            }
        }
    }
