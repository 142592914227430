/** ------------------------------
    Modal
    ------------------------------ */
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow-y: scroll;

    &:matches(.is-open) {
        display: block;
        }
    }

    /* Overlay */
    .modal__overlay {
        display: flex;
        padding: 2rem 0;
        min-height: 100%;
        background: rgba(0, 0, 0, .7);

        @media (width <= 500px) {
            padding: 1rem 0;
            }
        }

    /* Container */
    .modal__container {
        margin: auto;
        width: 95%;
        max-width: 630px;
        border-radius: 6px;
        background-color: #fff;
        box-shadow:
          0 0.6px 0.9px -12px rgba(0, 0, 0, 0.043),
          0 1.3px 2.2px -12px rgba(0, 0, 0, 0.062),
          0 2.3px 4.4px -12px rgba(0, 0, 0, 0.078),
          0 3.9px 9.1px -12px rgba(0, 0, 0, 0.097),
          0 10px 25px -12px rgba(0, 0, 0, 0.14)
        ;
        }

    /* Header */
    .modal__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 2rem 0;

        @media (width <= 500px) {
            padding: 1rem 1rem 0;
            }
        }
        .modal__title {
            margin: 0;
            font-size: 1.25rem;
            font-weight: 400;
            max-width: calc(100% - 40px);
            }
        .modal__close {
            background: transparent;
            border: 0;
            color: var(--brand-mid);
            font-size: .875rem;
            text-transform: uppercase;

            &:matches(:focus, :hover) {
                outline: 0;
                color: var(--brand-primary);
                }
            }

    /* Body */
    .modal__body {
        padding: 2rem;
        text-align: left;

        @media (width <= 500px) {
            padding: 1rem;
            }

        & a:not(.btn):hover {
            color: var(--brand-secondary);
            }

        & > :first-child {
            margin-top: 0;
            }

        & > :last-child {
            margin-bottom: 0;
            }
        }

    /* Footer */
    .modal__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;
        background-color: var(--brand-light);
        }

/** ------------------------------
    Modifiers
    ------------------------------ */
.modal__container--wide {
    max-width: 800px;
    }
