/** ------------------------------
    Tag Line
    ------------------------------ */
.tag-line {
    .tag-line-content{
        text-align: center;
        padding: 20px 0;
        font-size: 1.5rem;
        font-weight: 700;
        color: black;
    }
}
