/** ------------------------------
    Pikaday theme
    ------------------------------ */
.is-selected .pika-button,
.has-event .pika-button {
    background-color: var(--brand-secondary);
    box-shadow: inset 0 1px 3px var(--brand-secondary);
    }

.pika-button:hover,
.pika-row.pick-whole-week:hover .pika-button {
    background-color: var(--brand-secondary);
    }

.is-today .pika-button {
    color: var(--brand-secondary);

    &:hover {
        color: #fff;
        }
    }
