@define-mixin list-reset {
    list-style: none;
    margin: 0;
    padding-left: 0;
}


.process-list {
    @mixin list-reset;
    counter-reset: process;
    margin: 2rem 0;
    }
    .process-list__item {
        counter-increment: process;
        position: relative;
        margin-bottom: 1rem;
        padding-left: 3.5em;
        min-height: 2.5rem;

        &::before {
            content: counter(process);
            position: absolute;
            top: 0;
            left: 0;
            width: 2.5rem;
            height: 2.5rem;
            line-height: 2;
            border-radius: 50%;
            background-color: var(--brand-secondary);
            color: #fff;
            font-size: 1.25rem;
            text-align: center;
            }
        }



.check-list {
    @mixin list-reset;
    margin: 2rem 0;
    }
    .check-list__item {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 1rem;
        padding-left: 3.5em;
        min-height: 2.5rem;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            background: var(--brand-secondary) url('../../img/inline/check.svg') no-repeat 50% 50%;
            background-size: 24px 24px;
            color: #fff;
            font-size: 1.25rem;
            text-align: center;
            }
        }
