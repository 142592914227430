/** ------------------------------
    Base
    ------------------------------ */
@define-mixin button {
    display: inline-block;
    padding: 0 var(--btn-padding);
    min-height: var(--btn-height);
    line-height: var(--btn-height);
    border: 0;
    border-radius: var(--btn-border-radius);
    background-color: var(--btn-default-bg);
    font-family: var(--btn-font-family);
    font-weight: var(--btn-font-weight);
    color: var(--btn-default-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    min-width: 150px;
    margin: 5px 0;

    &:focus {
        outline: 0; /* TODO: consider accessibility */;
        }

    @media (hover: hover) {
        &:hover {
            transform: translateY(-1px);
            box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
            }
        }
}

.btn {
    @mixin button;
    }
    .btn__icon {
        display: inline-block;
        margin: 0 4px;
        vertical-align: middle;

        & > * {
            width: auto;
            height: 2em;
            }
        }

.btn-cta {
    margin: 2rem 0;
    }


/** ------------------------------
    Modifiers
    ------------------------------ */
.btn--block {
    width: 100%;
    }

.btn--lg {
    height: calc(var(--btn-height) * 1.25);
    line-height: calc(var(--btn-height) * 1.25);
    }

.btn--ghost {
    border: 1px solid currentcolor;
    background-color: transparent;
    color: inherit;
    }

.btn--primary {
    background-color: var(--brand-primary);
    }

.btn--secondary {
    background-color: var(--brand-secondary);
    }

.btn--light {
    background-color: var(--brand-light);
    color: var(--brand-primary);
    }

.btn--dark {
    background-color: var(--brand-dark);
    }

.btn--mid {
    background-color: var(--brand-mid);
    }


/** ------------------------------
    Button group
    ------------------------------ */
.btn-group {
    display: flex;
    flex-wrap: wrap;
    margin: -1em 0 0 -1em;

    & > .btn {
        margin-top: 1em;
        margin-left: 1em;
        flex: 1 1 200px;
        }
    }
