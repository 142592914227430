
.carousel {
    padding: 0;

    .glide.home-page-carousel {

        .glide__slide {
            border: 0;
            padding: 0;

            .page-hero {
                width: 100%;
            }
        }
    }
}
@media (max-width: 767px) {
    .carousel {
        .glide.home-page-carousel {
            .glide__slide {
                .page-hero {
                    .page-hero__body {
                        .container {
                            padding: 0 4rem;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .carousel {
        .glide.home-page-carousel {
            .glide__slide {
                .page-hero {
                    .page-hero__content {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}