
.carousel {
    padding: 0;
    .glide.home-page-statistics-carousel {
        z-index: 10;
        position: relative;
        color: #ffffff;
        max-width: 1100px;

        .glide__slide {
            border: 0;
            padding: 0;
            background: none;
        }
    }
}

.statistics-group {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    min-height: 600px;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.statistics-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    max-width: 1100px;
    margin: 3rem;
    box-shadow:
        0 .6px .9px -12px rgba(0,0,0,.043),
        0 1.3px 2.2px -12px rgba(0,0,0,.062),
        0 2.3px 4.4px -12px rgba(0,0,0,.078),
        0 3.9px 9.1px -12px rgba(0,0,0,.097),
        0 10px 25px -12px rgba(0,0,0,.14);
}

.statistics-panel__left {
    display: flex;
    flex: 1 1 40%;
    align-items: center;
    background: var(--brand-primary);
    justify-content: center;
    padding: 3rem;
    border-radius: 6px 0 0 6px;
}

.statistics-figure {
    font-size: 5rem;
    font-weight: 900;
}

.statistics-panel__right {
    display: flex;
    flex: 1 1 60%;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    background: linear-gradient(118.99901648274147deg, rgba(220, 220, 220,1) 23.12737015615694%,rgba(224, 224, 224,1) 28.398343469264937%,rgba(245, 245, 245,1) 56.43819330702799%);
    color: var(--brand-primary);
    border-radius: 0 6px 6px 0;
}

.glide__arrows {

    .glide__arrow--right {
        right: 0;
    }

    .glide__arrow--left {
        left: 0;
    }

}

@media (min-width: 767px) {
    .statistics-group {
        height: 70vh;
        max-height: 750px;
    }
}
