/** ------------------------------
    Sections
    ------------------------------ */
.section {
    margin: 5rem 0;
}
.flexible-content-main .section {
    margin: 3rem 0;
}
.flexible-content-main .section .card-grid .card-grid__item, .card-grid__item-quarter {
    margin-bottom: 1rem;
}
section.blog-list-section.section {
    margin: 2rem 0;
}
article.blog-post-section.section {
    margin: 0 0 3rem;
}
/** ------------------------------
    Modifiers
    ------------------------------ */
.section--padded {
    margin: 0;
    padding: 5rem 0;
}
.flexible-content-main .section--padded {
    margin: 0;
    padding: 4rem 0;
}
