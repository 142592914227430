/** ------------------------------
    Application Form
    ------------------------------ */
@custom-media --compact-form (width < 1024px), (height < 680px);
@custom-media --expanded-form (width >= 1024px) and (height >= 680px);

.app-form {
    margin-bottom: 0;
    }

.app-form__sidebar {
    background-color: #f1f1f1;
    }

.app-form__body {

    & label {
        font-weight: 400;
        }

    }
    .app-form__body > * {
        @mixin center-block;
        max-width: 800px;
        }
    .app-form__fieldset {
        margin: 2rem auto;
        padding: 20px;
        border: 1px solid #eee;
        border-radius: 5px;
        background-color: #fdfdfd;
        }
    .app-form__fieldset-legend {
        margin: 0;
        padding: 0 .5em;
        font-size: 1.25em;
        font-weight: 400;
        }
    .app-form__body > .btn {
        display: block;
        margin: auto;
        width: auto;
        }
    .app-form__body .message {
        @mixin base-margin;
        }

@media (--compact-form) {

    .app-form__sidebar {
        overflow: hidden;
        position: sticky;
        top: 0;
        z-index: 5;
        text-align: center;
        }
    .app-form__body {
        padding: 0 calc(var(--grid-gutter) / 2) 20px;
        }

}

@media (--expanded-form) {

    .app-form {
        display: flex;
        }
        .app-form__sidebar {
            width: 25%;
            min-width: 300px;
            border-right: 3px solid #929ea1;
            text-align: right;
            }
        .app-form__body {
            padding: 0 60px 40px;
            width: 75%;
            }

}

/* Disabled form state */
.app-form--disabled {

& fieldset {
      pointer-events: none;
  }

}

/** ------------------------------
    Progress
    ------------------------------ */
.app-form__progress {
    counter-reset: progress;
    @mixin list-reset;
    }
    .app-form__progress-item {
        counter-increment: progress;
        position: relative;
        color: #929ea1;
        font-size: .8rem;

        & p {
              margin-top: 0;
          }
        }
    .app-form__progress-item::after {
        content: counter(progress);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 45px;
        height: 45px;
        line-height: 40px;
        border: 3px solid currentcolor;
        border-radius: 50%;
        background-color: #fff;
        font-size: 1.5em;
        font-weight: 800;
        text-align: center;
        }
        .app-form__progress-item > * {
            margin-bottom: 0;
            }
        .app-form__progress-item > p {
            margin-left: auto;
            max-width: 220px;
            }
        .app-form__progress-title {
            margin-bottom: .25em;
            font-size: 1.25rem;
            font-weight: 800;
            }

    /* States */
    .app-form__progress-item:hover {
        color: var(--brand-primary);
        cursor: pointer;
        }
    .app-form__progress-item.is-complete {
        color: var(--brand-secondary);
        }
    .app-form__progress-item.is-complete::after {
        content: "";
        border-color: var(--brand-secondary);
        background: var(--brand-secondary) url('../../img/inline/check.svg') no-repeat 50% 50%;
        background-size: 32px 32px;
        }
    .app-form__progress-item.has-errors {
        color: #c00;
        }
    .app-form__progress-item.has-errors::after {
        content: "";
        border-color: #c00;
        background: #c00 url('../../img/inline/times.svg') no-repeat 50% 50%;
        background-size: 32px 32px;
        }
    .app-form__progress-item.is-active {
        color: var(--brand-primary);
        }
    .app-form__progress-item.is-active::after {
        border-color: var(--brand-primary);
        background-color: var(--brand-primary);
        color: #fff;
        }

@media (--compact-form) {

    .app-form__progress {
        display: flex;
        height: 65px;
        }
        .app-form__progress-item {
            flex: 1 1 auto;
            }
        .app-form__progress-item::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 100%;
            height: 3px;
            background-color: currentcolor;
            }
            .app-form__progress-item > * {
                display: none;
                }

}

@media (--expanded-form) {

    .app-form__progress {
        position: sticky;
        top: 145px;
        }
        .app-form__progress-item {
            padding: 30px 60px 30px 30px;
            }
            .app-form__progress-item::before {
                content: "";
                position: absolute;
                width: 3px;
                height: 100%;
                top: 0;
                right: -3px;
                background: currentcolor;
                }
            .app-form__progress-item::after {
                left: 100%;
                transform: translate3d(-50%, -50%, 0);
                width: 60px;
                height: 60px;
                line-height: 55px;
                }

}

/** ------------------------------
    Body
    ------------------------------ */
.app-form__section-header {
    font-weight: 500;
    margin: 20px 0 0;
}
.app-form__body .school-lookup {
    position: relative;
}
#school-lookup-control-autocomplete {
    max-height: 270px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    background: #fff;
    z-index: 999;
    padding: 0 5px;
    border: 1px solid #bcc6d6;
    border-top: none;
}
#school-lookup-control-autocomplete .autocomplete__results-item {
    margin: 5px 0;
    padding: 0 5px;
    border-radius: 3px;
    cursor: pointer;
}

#school-lookup-control-autocomplete .autocomplete__results-item:hover, #school-lookup-control-autocomplete .autocomplete__results-item.selected {
    background: #ff006d;
    color: #fff;
}

.app-form .form__group.loading {
    background: url(../resources/assets/img/loading.gif) no-repeat top right transparent;
    background-size: 18px;
}

.app-form fieldset.app-form__fieldset:first-of-type .title {
    display: none;
}