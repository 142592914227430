/** ------------------------------
    User Account
    ------------------------------ */
.user-account {
    }
    .user-account__actions {
        margin-bottom: 2rem;
        text-align: right;
        }


/** ------------------------------
    GovernorHub notice
    ------------------------------ */
.gh-notice {
    margin-bottom: 3rem;
    padding: 2em;
    border-radius: .5em;
    background-color: var(--brand-light);

    & > :first-child {
        margin-top: 0;
        }

    & > :last-child {
        margin-bottom: 0;
        }

    }
