/** ------------------------------
    Base
    ------------------------------ */
.nav,
nav :matches(ul, ol) {
    @mixin list-reset;
    }


/** ------------------------------
    Modifiers
    ------------------------------ */
.nav--inline {
    & li {
        display: inline-block;
        }

    & > li + li {
        margin-left: 20px;
        }
    }

.nav--stacked {
    & a {
        display: block;
        padding-top: calc(var(--base-line-height) / 2 * 1rem);
        padding-bottom: calc(var(--base-line-height) / 2 * 1rem);
        border-bottom: 0;
        }
    }
