/** ------------------------------
    Page Header
    ------------------------------ */
.page-header {
    position: relative;
    /* margin: 4rem 0 6rem; */
    margin: 4rem 0 0;

&::before {
     content: "";
     position: absolute;
     top: 0;
     left: 75%;
     width: 100vw;
     max-width: 1200px;
     padding-bottom: 100%;
     background: url('../../img/inline/rings.svg') no-repeat 50% 50%;
     background-size: contain;
     transform: translate3d(-50%, -50%, 0);
     opacity: .1;
     z-index: -1;
 }
}
.page-header__title {
    margin-bottom: 0;
    font-size: calc(4vw + 1rem);
    color: var(--brand-secondary);

@media (width >= 800px) {
    font-size: 3rem;
}

& + * {
      margin-top: 2rem;
  }
}
.page-header__lead {
    /* max-width: 540px; */
    font-size: 1.25rem;
}

.page-breadcrumbs-container {
    width: 100%;
    margin: 50px auto 0;
}
.page-breadcrumbs ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}
.page-breadcrumbs ul li {
    margin-right: 10px;
}
.page-breadcrumbs ul li:after {
    content: "|";
    margin-left: 15px;
}
.page-breadcrumbs ul li:last-child:after {
    display: none;
}