/** ------------------------------
    Heading action
    ------------------------------ */
.heading-action {
    margin-bottom: 2rem;

    & > * {
        margin-bottom: 0;
        }
    }
    .heading-action__action {
        display: none;
        }

@media (width > 500px) {

    .heading-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        }
        .heading-action__action {
            display: block;
            padding-left: 1rem;
            }

}
