/** ------------------------------
    Media Object
    ------------------------------ */
.media-object {
    display: flex;
    margin: 3rem 0;
    }
    .media-object__media {

        & > img {
            min-width: 100%;
            border-radius: 4px;
            box-shadow:
              0 0.6px 0.9px -12px rgba(0, 0, 0, 0.043),
              0 1.3px 2.2px -12px rgba(0, 0, 0, 0.062),
              0 2.3px 4.4px -12px rgba(0, 0, 0, 0.078),
              0 3.9px 9.1px -12px rgba(0, 0, 0, 0.097),
              0 10px 25px -12px rgba(0, 0, 0, 0.14)
            ;
            }

        }
    .media-object__body {
        flex: 1;
        padding-left: 2rem;

        & > :last-child {
            margin-bottom: 0;
            }
        }
        .media-object__title {
            margin-bottom: .25em;
            font-size: 1.25rem;
            font-weight: 400;
            }

@media (width < 600px) {

    .media-object {
        flex-direction: column;
        }
        .media-object__body {
            padding: 1rem 0 0;
            }

}


/** ------------------------------
    Modifier
    ------------------------------ */
@media (width >= 600px) {

    .media-object--reverse {
        flex-direction: row-reverse;

        & .media-object__body {
            padding-left: 0;
            padding-right: 2rem;
            }
        }

}
