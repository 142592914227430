/** ------------------------------
    Base
    ------------------------------ */
.alert {
    margin: 1rem 0;
    padding: 1em;
    border: 2px solid rgba(0, 0, 0, .2);
    border-radius: 4px;
    font-weight: 500;
    color: #fff;

    & a {
        color: currentcolor;
        }

    & > :first-child {
        margin-top: 0;
        }

    & > :last-child {
        margin-bottom: 0;
        }
    }


/** ------------------------------
    Modifiers
    ------------------------------ */
.alert--error {
    background-color: #c00;
    }

.alert--success {
    background-color: #219739;
    }

.alert--notice {
    background-color: var(--brand-mid);
    }
