/** ------------------------------
    Alignments
    ------------------------------ */
@define-mixin center-block {
    margin-left: auto;
    margin-right: auto;
}

.center-block {
    @mixin center-block;
    }

@define-mixin align $alignment, $force: false {
    @if $force == true {
        text-align: $alignment !important;
    } @else {
        text-align: $alignment;
    }
}

@define-mixin pull $alignment, $force: false {
    @if $force == true {
        float: $alignment !important;
    } @else {
        float: $alignment;
    }
}

.align-center {
    @mixin align center, true;
    }

.align-left {
    @mixin align left, true;
    }

.align-right {
    @mixin align right, true;
    }

.pull-left {
    @mixin pull left, true;
    }

.pull-right {
    @mixin pull right, true;
    }

.pull-none {
    @mixin pull none, true;
    }

.flex-pull-left {
    margin-right: auto !important;
    }

.flex-pull-right {
    margin-left: auto !important;
    }


/** ------------------------------
    Layout
    ------------------------------ */
.no-margin {
    margin: 0 !important;
    }

.no-padding {
    padding: 0 !important;
    }


/** ------------------------------
    Visibility
    ------------------------------ */
.hidden {
    display: none !important;
    }

@define-mixin visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.visually-hidden {
    @mixin visually-hidden;
    }


/** ------------------------------
    Brand colours
    ------------------------------ */
.bg-brand-primary {
    background-color: var(--brand-primary) !important;
    }

.bg-brand-secondary {
    background-color: var(--brand-secondary) !important;
    }

.bg-brand-light {
    background-color: var(--brand-light) !important;
    }

.bg-brand-dark {
    background-color: var(--brand-dark) !important;
    }

.fg-brand-primary {
    color: var(--brand-primary) !important;
    }

.fg-brand-secondary {
    color: var(--brand-secondary) !important;
    }

.fg-brand-light {
    color: var(--brand-light) !important;
    }

.fg-brand-dark {
    color: var(--brand-dark) !important;
    }


/** ------------------------------
    Images
    ------------------------------ */
.img-circle {
    border-radius: 50% !important;
    }

.img-shadow {
    border-radius: 4px;
    box-shadow:
      0 0.6px 0.9px -12px rgba(0, 0, 0, 0.043),
      0 1.3px 2.2px -12px rgba(0, 0, 0, 0.062),
      0 2.3px 4.4px -12px rgba(0, 0, 0, 0.078),
      0 3.9px 9.1px -12px rgba(0, 0, 0, 0.097),
      0 10px 25px -12px rgba(0, 0, 0, 0.14)
    ;
    }


/** ------------------------------
    Brand rings
    ------------------------------ */
.brand-rings {
    position: relative;
    /*margin: 10rem 0;*/

    & > :first-child {
        /*margin-top: 0;*/
        }

    & > :last-child {
        /*margin-bottom: 0;*/
        }

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100vw;
        max-width: 1200px;
        padding-bottom: 100%;
        background: url('../../img/inline/rings.svg') no-repeat 50% 50%;
        background-size: contain;
        transform: translate3d(-50%, -50%, 0);
        opacity: .15;
        z-index: -1;
        }
    }


/** ------------------------------
    Skip link
    ------------------------------ */
.skip-link {
    @mixin visually-hidden;
    background-color: var(--brand-secondary);
    color: #fff;
    text-decoration: none;

    &:focus {
        padding: 1em;
        margin: 0;
        width: auto;
        height: auto;
        clip: auto;
        }
    }

.site-notice-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: rgba(0,0,0,0.2);
}
.site-notice-popup .notice-content-wrapper {
    top: 50%;
    text-align: center;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
}
.site-notice-popup .notice-content {
    width: 500px;
    max-width: 90%;
    background: #fff;
    padding: 35px 15px 30px;
    display: inline-block;
    margin: 0 auto;
    border: 3px solid #203c46;
    box-shadow: 0px 0px 15px 0px #000;
    z-index: 999999999;
    position: relative;
}

.site-notice-popup a.site-notice-popup-close {
    position: absolute;
    right: 5px;
    top: 5px;
    text-transform: uppercase;
    width: 24px;
    border: 1px solid;
    border-radius: 100%;
    height: 24px;
}

.text-center{
    text-align: center;
}

.overflow-hidden{
    overflow: hidden;
}

body.mobile-menu-open nav.site-header__menu {
    overflow-y: scroll;
}