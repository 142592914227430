/** ------------------------------
    Tooltip
    ------------------------------ */
.tooltip {
    display: none;
    position: absolute;
    top: -10px;
    padding: 1em;
    width: 100%;
    border-radius: .25em;
    background-color: var(--brand-light);
    box-shadow:
      0 0.6px 0.9px -12px rgba(0, 0, 0, 0.043),
      0 1.3px 2.2px -12px rgba(0, 0, 0, 0.062),
      0 2.3px 4.4px -12px rgba(0, 0, 0, 0.078),
      0 3.9px 9.1px -12px rgba(0, 0, 0, 0.097),
      0 10px 25px -12px rgba(0, 0, 0, 0.14)
    ;
    font-size: .875rem;
    text-align: left;
    transform: translateY(-100%);

    &::after {
        content: "";
        position: absolute;
        left: 10px;
        bottom: -9px;
        width: 0px;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid var(--brand-light);
        }
    }

/* Target element */
[data-tooltip] {
    cursor: pointer;

    &::after {
        content: "i";
        display: inline-block;
        margin-left: .25em;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: var(--brand-secondary);
        font: italic bold .875rem/16PX var(--font-serif); /* line-height defined with uppercase 'PX' to be ignored by pxtorem [WIP] */
        color: #fff;
        text-align: center;
        vertical-align: middle;
        }

    &:hover + .tooltip {
        display: block;
        }
    }
