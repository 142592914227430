/** ------------------------------
    CTA Panel
    ------------------------------ */
.cta-panel-group {
    display: flex;
    flex-wrap: wrap;
    margin: 3rem auto;
    max-width: 1100px;
    overflow: hidden;
    border-radius: 6px;
    box-shadow:
        0 5.3px 4.2px -4px rgba(0, 0, 0, 0.02),
        0 11.1px 10.1px -4px rgba(0, 0, 0, 0.028),
        0 18.1px 19px -4px rgba(0, 0, 0, 0.035),
        0 27.6px 34px -4px rgba(0, 0, 0, 0.042),
        0 43.9px 63.5px -4px rgba(0, 0, 0, 0.05),
        0 100px 152px -4px rgba(0, 0, 0, 0.07)
    ;

    }
    .cta-panel {
        display: flex;
        flex: 1 1 400px;
        flex-direction: column;
        padding: 4.5%;
        background: var(--brand-primary);
        color: #fff;

        &:nth-child(even) {
            background: linear-gradient(118.99901648274147deg, rgba(220, 220, 220,1) 23.12737015615694%,rgba(224, 224, 224,1) 28.398343469264937%,rgba(245, 245, 245,1) 56.43819330702799%);
            color: var(--brand-primary);
            }

        & > p {
            margin-top: 0;

            &:last-of-type {
                margin-bottom: 3rem;
                }
            }
        }
        .cta-panel__title {
            font-size: 2rem;
            }
        .cta-panel__cta {
            margin-top: auto;
            }

@media (width > 900px) {

    .cta-panel__title {
        max-width: 20ch;
        }

}
