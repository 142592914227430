/** ------------------------------
    Base
    ------------------------------ */
html {
    font: var(--base-font-weight) var(--base-font-size)/var(--base-line-height) var(--base-font-family);
    color: var(--base-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

::selection {
    background-color: var(--brand-secondary);
    color: white;
    }


/** ------------------------------
    Links
    ------------------------------ */
:matches(a, .faux-link) {
    color: currentcolor;
    text-decoration: underline;
    text-decoration-skip-ink: auto;
    }

main :matches(a, .faux-link) {
    color: var(--brand-secondary);
    }


/** ------------------------------
    Headings
    ------------------------------ */
h1, h2, h3, h4, h5, h6 {
    margin: 0 0 2rem;
    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
    line-height: 1.25;
    }

h1 {
    font-size: 2rem;
    }

h2 {
    font-size: 1.5rem;
    }

h3 {
    font-size: 1.17rem;
    }

h4 {
    font-size: 1.5rem;
    }

h5 {
    font-size: 1.25rem;
    }

h6 {
    font-size: .75rem;
    }

.hd-lg {
    font-size: 3rem;
    }

.hd-md {
    font-size: 2rem;
    }

.hd-sm {
    margin-bottom: 1em;
    font-size: 1.25rem;
    font-weight: 400;
}

.hd-bd {
    margin-bottom: 1em;
    font-size: 1rem;
    font-weight: 400;
}


/** ------------------------------
    Paragraphs
    ------------------------------ */
p {
    margin: var(--base-rhythm) 0;
    }


/** ------------------------------
    Helpers
    ------------------------------ */
.muted {
    color: #999;
    }
