/** ------------------------------
    Panel
    ------------------------------ */
.panel {
    padding: 2rem;
    border-radius: 6px;
    background-color: var(--brand-light);
    box-shadow:
      0 0.6px 0.9px -12px rgba(0, 0, 0, 0.043),
      0 1.3px 2.2px -12px rgba(0, 0, 0, 0.062),
      0 2.3px 4.4px -12px rgba(0, 0, 0, 0.078),
      0 3.9px 9.1px -12px rgba(0, 0, 0, 0.097),
      0 10px 25px -12px rgba(0, 0, 0, 0.14)
    ;
    }
    .panel__title {
        margin-bottom: 2rem;
        font-size: 2rem;
        font-weight: 400;
        }
    .panel__body {

        & > :last-child {
            margin-bottom: 0;
            }

        }
