/** ------------------------------
    Base
    ------------------------------ */
@define-mixin form-control {
    display: block;
    width: 100%;
    margin: var(--form-control-margin) 0 0;
    padding: 0 var(--form-control-padding);
    height: var(--form-control-height);
    line-height: var(--form-control-height);
    border: var(--form-control-border-width) solid var(--form-control-default-border-color);
    border-radius: var(--form-control-border-radius);
    background-color: var(--form-control-default-bg);
    font-family: var(--btn-font-family);
    font-weight: 300;
    color: var(--form-control-default-color);
    appearance: none; /* Remove iOS border-radius */

    &:focus {
        outline: none;
        border-color: var(--form-control-default-border-color-active);
        }
}

@define-mixin form-label {
    display: inline-block;
    font-weight: 400;
}

@define-mixin form-group {
    position: relative;
    margin-top: 0;
    margin-bottom: 1.25rem;
}

.form {
    @mixin base-margin;
    position: relative;
    }
    .form__group {
        @mixin form-group;
        }
    .form__label {
        @mixin form-label;
        }
    .form__control {
        @mixin form-control;
        }

    /* Fix padding of multi-line fields */
    textarea.form__control,
    select.form__control[multiple] {
        padding: var(--form-control-padding);
        height: auto;
        line-height: var(--base-line-height);
        }

    /* Colour pickers don't need to be 100% */
    .form__control[type="color"] {
        width: calc(var(--form-control-height) + 1rem);
        }

/* Rows */
.form__group-row {
    @mixin grid-container;
    display: flex;
    flex-wrap: wrap;

    & > .form__group {
        flex: 1 1 300px;
        @mixin grid-item;
        }
    }


/** ------------------------------
    States
    ------------------------------ */
.notice-list,
.error-list {
    padding-left: 1.25em;
    }

.notice-list,
.notice {
    color: var(--brand-secondary);
    }

.error-list,
.error {
    color: #c00;
    }

.has-error {

    & *:not(.tooltip, [data-tooltip]) {
        color: #c00 !important;
        border-color: currentcolor !important;
        }

    }

.help-text {
    margin-top: 0;
    }


/** ------------------------------
    Password strength
    ------------------------------ */
.password-strength {
    margin: .5rem 0;
    height: 12px;
    border-radius: 2px;
    background: #ddd;
    }

.password-strength--score-0 {
    background: linear-gradient(
        to right,
        #bf2626 0%,
        #f28926 33%,
        #ddd 33%
        );
    }

.password-strength--score-1 {
    background: linear-gradient(
        to right,
        #bf2626 0%,
        #f28926 33%,
        #a2e524 66%,
        #ddd 66%
        );
    }

.password-strength--score-2 {
    background: linear-gradient(
        to right,
        #bf2626 0%,
        #f28926 33%,
        #a2e524 66%,
        #6aa559 100%
        );
    }
.support-preferences {
    margin-top: 20px;
}

.field-error-container.error-container.error div {
    background: #f5e4e4;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    font-size: 14px;
}