/** ------------------------------
    Page Hero
    ------------------------------ */
.page-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 70vh;
    min-height: 600px;
    max-height: 750px;
    background-color: var(--brand-primary);
    color: #fff;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 80%;
        max-width: 600px;
        height: 100%;
        background: linear-gradient(to right, hexa(var(--brand-primary), .84) 30%, transparent);
        z-index: 5;
        pointer-events: none;
        }
}
.page-hero__media {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: .8;
    z-index: 1;

    & > img {
        width: auto;
        min-width: 100%;
        max-width: none;
        height: auto;
        transform: translateY(-25%);
    }
}

.page-hero__body {
    position: relative;
    z-index: 10;
}
.page-hero__title {
    font-size: calc(2vw + 1.5rem);

    @media (width > 1200px) {
        font-size: 3rem;
    }
}

.page-hero__content {
        max-width: 580px;

    & p {
        text-shadow: 4px 4px 10px rgba(0, 0, 0, .6);

        &:last-of-type {
            margin-bottom: 3rem;
        }
    }

}

@supports (object-fit: cover) {

    .page-hero__media > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: none;
    }

}

@media (max-width: 1250px) {
    .page-hero__content {
        margin: auto;
        text-align: center;
    }
}

@media (height < 600px) {

    .page-hero {
        min-height: 650px;
    }
}
