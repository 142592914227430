/** ------------------------------
    Dashboard widget
    ------------------------------ */
.dashboard-widget {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid var(--brand-light);
    background-color: #fff;
    box-shadow:
      0 0.6px 0.9px -12px rgba(0, 0, 0, 0.043),
      0 1.3px 2.2px -12px rgba(0, 0, 0, 0.062),
      0 2.3px 4.4px -12px rgba(0, 0, 0, 0.078),
      0 3.9px 9.1px -12px rgba(0, 0, 0, 0.097),
      0 10px 25px -12px rgba(0, 0, 0, 0.14)
    ;
    }
    .dashboard-widget__header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 1.25rem 3%;
        border-bottom: 1px solid var(--brand-light);

        @media (width >= 700px) {
            padding: 1.25rem;
            }

        & > :not(:first-child) {
            margin-left: 1rem;

            &:last-child {
                margin-left: auto;
                }
            }

            @media (width < 600px) {

                & :matches(small, .custom-select-control) {
                    order: 99;
                    flex-basis: 100%;
                    margin-top: .5rem;
                    margin-left: 0 !important;
                    }

                }
        }
        .dashboard-widget__title {
            margin-bottom: 0;
            font-size: 1.125rem;
            font-weight: 500;
            }
    .dashboard-widget__body {
        flex-grow: 1;
        padding: 0 1.5rem;
        & .table-responsive {
            margin: 0;
            height: 100%;
            overflow-x: visible;
            .tooltip {
                width: 300px;
                word-wrap: inherit;
                white-space: normal;
                height: auto;
                border: 1px solid #ccc;
                &::after {
                    border-top: 10px solid #ccc;
                }
            }
            @media (max-width: 820px) {
                overflow-x: auto;
            }
        }

        & .card {
            padding: .5rem;
            }

        & .pagination {
            margin: 0;
            padding: 1rem 0;
            border-top: 1px solid var(--brand-light);
            }
        }
    .dashboard-widget__footer {
        padding: 1.5rem;
        }


/** ------------------------------
    Modifiers
    ------------------------------ */
.dashboard-widget--full-width {
    grid-column: 1 / span 2;
    }


.v-step-nav {
    display: inline-block;
    width: 100%;
}
.dashboard .page-header, .dashboard-widget.v-step-dashboard-applications .dashboard-widget__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > a {
        margin: 0;
        text-decoration: none;
        & > span {
            text-decoration: underline;
        }
        & > svg {
            width: 24px;
        }
    }
}

#archive-confirm-modal .modal__body .btn{margin-top:5px}
