/** ------------------------------
    RTE Content
    ------------------------------ */
.rte {

    & :matches(.responsive-media) {
        margin: 2rem 0;
        }

    & img {
        display: block;
        margin: 3rem auto;
        max-width: 700px;
        border-radius: 4px;
        box-shadow:
          0 0.6px 0.9px -12px rgba(0, 0, 0, 0.043),
          0 1.3px 2.2px -12px rgba(0, 0, 0, 0.062),
          0 2.3px 4.4px -12px rgba(0, 0, 0, 0.078),
          0 3.9px 9.1px -12px rgba(0, 0, 0, 0.097),
          0 10px 25px -12px rgba(0, 0, 0, 0.14)
        ;
        }

    /* Lists */
    & :matches(ol, ul) {
        @mixin list-reset;
        margin: 2rem 0;

        & > li {
            position: relative;
            margin-bottom: 1rem;
            padding-left: 3.5em;
            min-height: 2.5rem;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 50%;
                background: var(--brand-secondary) url('../../img/inline/check.svg') no-repeat 50% 50%;
                background-size: 24px 24px;
                color: #fff;
                font-size: 1.25rem;
                text-align: center;
                }
            }
        }

    /* Numbered list */
    & ol {

        & > li {
            counter-increment: process;

            &::before {
                content: counter(process);
                background-image: none;
                line-height: 2.5rem;
                }
            }

        }

    }
