/** ------------------------------
    Pagination
    ------------------------------ */
.pagingation {
    @mixin list-reset;
    display: flex;
    justify-content: center;
    margin: 3rem 0;
    }
    .pagingation__item {
        margin: 0 .5rem;

        & > a {
            display: block;
            min-width: 2rem;
            line-height: calc(2rem - 2px);
            border-radius: 1rem;
            border: 1px solid var(--brand-light);
            color: var(--brand-secondary);
            text-align: center;
            text-decoration: none;

            &:hover {
                background-color: var(--brand-secondary);
                color: #fff;
                }
            }
        }
    .pagination__item--prev,
    .pagination__item--next {

        & > a {
            padding: 0 1em;
            }

        }
    .pagination__item--current {
        min-width: 2rem;
        line-height: 2rem;
        border-radius: 1rem;
        background-color: var(--brand-light);
        text-align: center;
        }
