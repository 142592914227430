
.full-width-media-block {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 70vh;
    min-height: 600px;
    max-height: 750px;
    align-items: center;
    justify-content: center;
    background-color: var(--brand-primary);

    .full-width-media-block__media {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        z-index: 1;
    }

    .full-width-media-block__body {
        z-index: 10;
        position: relative;
        color: #ffffff;
        max-width: 1100px;

        .cta-panel__title {
            max-width: 40ch;
        }
    }
}

.full-width-media-block__media-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
