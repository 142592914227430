/** ------------------------------
    Flatpickr
    ------------------------------ */
.flatpickr-day {

    &.selected {
        border-color: var(--brand-secondary) !important;
        background: var(--brand-secondary) !important;
        }

    }
