
.carousel {
    padding: 0;

    .glide.home-page-testimonial-carousel {
        z-index: 10;
        position: relative;
        color: #ffffff;
        max-width: 1100px;

        .glide__slide {
            border: 0;
            padding: 0;
            background: none;

            .testimonial__content {
                display: flex;
                flex: 1;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                min-height: 400px;
                max-width: 700px;
                font-family: stolzl, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
                font-weight: 800;
                color: #fff;
                .quote, blockquote{
                    text-align: center;
                }
                p {
                    font-size: 1.1em;
                    font-style: italic;
                    max-width: 500px;
                    text-align: center;
                }

                cite {
                    border-top: 3px solid #ff006d;
                    padding-top: 18px;
                    margin-top: 0;
                    display: inline-block;
                    font-size: 18px;
                }
            }
        }

        .glide__arrows {
            .glide__arrow--right {
                right: 0;
            }
            .glide__arrow--left {
                left: 0;
            }
        }
    }
}

.testimonial__section {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 600px;
    align-items: center;
    justify-content: center;
    background-color: var(--brand-primary);

    .container {
        max-width: 100%;
    }
}

.testimonial-bg-image {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 1;
}

.testimonial-bg-image__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}


.testimonial__section-title {
    z-index: 10;
    position: relative;
    color: #fff;
    max-width: 1100px;
    text-align: center;
    margin-top: 3rem;
}

.testimonial__section-title .cta-panel__title {
    margin: 0 auto;
    text-align: center;
}

@media (min-width: 767px) {
    .testimonial__section {
        height: 70vh;
        max-height: 750px;
    }
}
