/** ------------------------------
    Card
    ------------------------------ */
.card {
    position: relative;

    &:matches(:hover, :focus-within) {

        & a:not(.btn) {
            color: var(--brand-secondary);
        }

        & img {
            box-shadow: 0 0.6px 0.9px -12px rgba(0, 0, 0, 0.043),
            0 1.3px 2.2px -12px rgba(0, 0, 0, 0.062),
            0 2.3px 4.4px -12px rgba(0, 0, 0, 0.078),
            0 3.9px 9.1px -12px rgba(0, 0, 0, 0.097),
            0 10px 25px -12px rgba(0, 0, 0, 0.14);
        }

    }
}

.card__media {
    position: relative;
    margin-bottom: 1.5rem;
    padding-bottom: calc(9 / 16 * 100%);

    /* Images are cropped to 16:9 */

    & > img {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }
}

.card__body {

    & > :last-child {
        margin-bottom: 0;
    }

    & > a {
        position: relative;
        z-index: 15; /* Above pseudo cover */
    }

}

.card__title {
    color: var(--brand-primary);

    & > a {
        color: currentcolor;
        text-decoration: none;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
        }
    }
}

.card__meta {
    display: flex;
    margin-bottom: .75em;
    font-size: .875em;

    & > .icon {
        margin-right: .5em;
        fill: var(--brand-secondary);
    }
}


/** ------------------------------
    Modifiers
    ------------------------------ */
.card--horizontal {
    display: flex;

    & .card__media {
        flex: 0 0 100px;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    & .card__body {
        padding-left: 1rem;
    }
}


/** ------------------------------
    Card grid
    ------------------------------ */
.card-grid {
    display: flex;
    flex-wrap: wrap;
    @mixin grid-container;
}

.card-grid__item-single {
    @mixin grid-item;
    width: 100%;
    margin-bottom: 3rem;
}

.card-grid__item-half {
    @mixin grid-item;
    width: 50%;
    margin-bottom: 3rem;
}

.card-grid__item {
    @mixin grid-item;
    width: 33.3333%;
    margin-bottom: 3rem;
}

.card-grid__item-quarter {
    @mixin grid-item;
    width: 25%;
    margin-bottom: 3rem;
}

@media (width < 840px) {

    .card-grid__item-single, .card-grid__item-half, .card-grid__item, .card-grid__item-quarter {

        /*&:first-child {
            width: 100%;
            }*/
        width: 100%;

    }

}

@media (width < 550px) {

    .card-grid__item-single, .card-grid__item-half, .card-grid__item, .card-grid__item-quarter {
        width: 100%;
    }

}


/** ------------------------------
    Card stack
    ------------------------------ */
.card-stack {
    @mixin list-reset;
}

.card-stack__item {

    &:not(:first-child) {
        border-top: 1px solid var(--brand-light);
    }

}
