/** ------------------------------
    Table
    ------------------------------ */
.table {
    margin: 2rem 0;
    min-width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    font-size: .875rem;
    text-align: left;
    white-space: nowrap;

    & a:hover {
        text-decoration: none;
        }

    & :matches(th, td) {
        padding: 1em;
        }

    & th {
        background-color: var(--brand-light);
        font-weight: 400;
        }

    & tbody > tr {
        border-bottom: 1px solid var(--brand-light);
        }
    }


/** ------------------------------
    Responsive
    ------------------------------ */
.table-responsive {
    overflow-x: auto;
    margin: 2rem 0;

    & > table {
        margin: 0; /* Prevent uncollapsed margins */
        min-width: 100%;
        }
    }
