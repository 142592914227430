/** ------------------------------
    Team Card
    ------------------------------ */
.team-card {
    display: flex;
    margin-bottom: 3rem;
    }
    .team-card__media {
        flex-shrink: 0;
        }
    .team-card__body {
        padding-left: 2rem;

        & > :last-child {
            margin-bottom: 0;
            }
        }
        .team-card__title {
            margin-bottom: .25em;
            font-size: 1.25rem;
            font-weight: 400;
            }

@media (width < 600px) {

    .team-card {
        flex-direction: column;
        }
        .team-card__body {
            padding-top: 1rem;
            padding-left: 0;
            }

}
