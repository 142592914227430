/** ------------------------------
    Select
    ------------------------------ */
.custom-select-control {
    margin: var(--form-control-margin) 0;
    border: var(--form-control-border-width) solid var(--form-control-default-border-color);
    border-radius: var(--form-control-border-radius);
    background-color: var(--form-control-default-bg);
    background-image: url('../../img/inline/select-arrow.svg');
    background-position: calc(100% - 15px) 50%;
    background-repeat: no-repeat;
    background-size: 8px auto;

    &:focus-within {
        border-color: var(--form-control-default-border-color-active);
        }

    & > select {
        @mixin form-control;
        margin: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;

        &:focus {
            outline: 0;
            }

        &::-ms-expand {
            display: none;
            }
        }
    }


/** ------------------------------
    File
    ------------------------------ */
.custom-file-control {
    margin: var(--form-control-margin) 0;
    }
    .custom-file-control__btn {
        position: relative;
        display: inline-block;

        & [type="file"] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            }
        }


/** ------------------------------
    Checkbox/radio
    ------------------------------ */
.custom-choice-control {
    margin: var(--form-control-margin) 0;
    display: flex;
    align-items: center;

    & input {
        visibility: hidden;
        position: absolute;
        z-index: -1;
        }

    & label {
        font-weight: 300 !important;

        &::before {
            content: "";
            flex-shrink: 0;
            width: 1.25em;
            height: 1.25em;
            margin-right: .5em;
            border-radius: var(--form-control-border-radius);
            border: var(--form-control-border-width) solid var(--form-control-default-border-color);
            background-color: #fff;
            transition: background-color .2s ease;
            display: inline-block;
            vertical-align: text-bottom;
            }
        }

    /* Radio */
    & input[type="radio"] {

        & ~ label::before {
            border-radius: 50%;
            }

        }

    /* Checked */
    & input:checked {

        &:matches([type="checkbox"]) ~ label::before {
            border-color: transparent;
            background: var(--brand-secondary) url('../../../assets/img/inline/check.svg') no-repeat 50% 50%;
            background-size: 80% 80%;
            }

        &:matches([type="radio"]) ~ label::before {
            background-color: var(--brand-secondary);
            box-shadow: inset 0 0 0 .15em #fff;
            }

        }

    /* Disabled */
    & input:disabled {

        & ~ label {
            pointer-events: none;
            }

        & ~ label::before {
            opacity: .8;
            }

        &:checked ~ label::before {
            background-color: var(--form-control-default-border-color);
            }

        }
    }


/** ------------------------------
    Autocompelte
    ------------------------------ */
.autocomplete {
    position: relative;
    @mixin form-control;

& > input[type="text"] {
      width: 100%;
      padding: 0;
      border: 0;

&:focus {
     outline: 0;
 }
}
}
.autocomplete__results {
    overflow-y: auto;
    position: absolute;
    z-index: 100;
    top: calc(100% -1px);
    left: -1px;
    width: calc(100% + 2px);
    max-height: calc(6 * 2.5rem);
    border: 1px solid var(--form-control-default-border-color);
    border-top: 0;
    background-color: #fff;

&:empty {
     display: none;
 }
}
.autocomplete__results-item {
    padding: 0 var(--form-control-padding);
    line-height: 2.5rem;

&:hover {
     cursor: pointer;
     background-color: #f3f3f3;
 }
}

#skill-step-container {
    width: 100%;
}

#skill-step-container .app-form__section-header{
    padding: 0 1rem;
    margin-bottom: 10px;
}

#skill-step-container .app-form__skill-progress {
    height: 5px;
    margin: 0 1rem 20px;
    display: block;
    background: #bcc6d6;
}
#skill-step-container #skill-step-navigator {
    padding: 0 1rem;
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
    text-align: center;
}
#skill-step-container .skill-step {
    display: inline-block;
}
#skill-step-container #skill-next-step {
    margin-left: 10px;
}
#skill-step-container .app-form__skill-progress .app-form__skill-progress-inner {
    background: #fe006d;
    height: 100%;
    width: 0px;
}

#skill-step-container .app-form__section-validator {
    text-align: right;
    font-size: 14px;
    margin-bottom: 10px;
    padding-right: 15px;
    color: #cc0000;
}

#skill-step-container .app-form__section-validator.pass {
    color: green;
}