.pass-information-help {
    cursor: pointer;
    background: #ff006d;
    color: #fff;
    width: 15px;
    height: 15px;
    display: inline-block;
    line-height: 17px;
    border-radius: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    float: right;
}

.password-validation-rules {
    display: none;
    position: absolute;
    right: -46%;
    background: #fff;
    padding: 10px;
    margin: 0;
    z-index: 9;
    box-shadow: 0 7px 31px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.password-validation-rules.top {
    top: -112%;
}

.password-validation-rules.bottom {
    top: 25%;
}

.password-validation-rules.show {
    display: block;
}

.password-validation-rules.top.show::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 47%;
    margin-left: -5px;
    border: 10px solid transparent;
    border-top-color: #fff;
}

.password-validation-rules.bottom.show::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 47%;
    margin-left: -5px;
    border: 10px solid transparent;
    border-bottom-color: #fff;
}

.password-validation-rules ul {
    margin: 0;
    padding-left: 13px;
}

.password-validation-rules ul li.rule {
    margin: 0;
    border: 0;
}

.password-validation-rules ul li.success {
    color: #008000;
    font-weight: 500;
}

.password-validation-rules ul li.rule.invalid {
    color: #ff0000;
    font-weight: 600;
}
