.feed-panel-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    grid-gap: 30px;
}

.feed-panel {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.feed-panel .card.card--horizontal {
    padding: 1rem;
    min-height: 110px;
}

.feed-panel .card.card--horizontal .card__media {
    object-fit: cover;
    flex: 0 0 130px;
    min-height: 80px;
    max-height: 80px;
}

@media (max-width: 767px) {
    .feed-panel-group {
        display: block;
    }
}
