/** ------------------------------
    Social List
    ------------------------------ */
.social-list {
    @mixin list-reset;
    margin-top: var(--base-rhythm);
    margin-bottom: var(--base-rhythm);
    display: flex;
    align-items: center;
    color: currentcolor;
    }
    .social-list__item {

        &:not(:first-child) {
            margin-left: 1em;
            }

        & a {
            display: block;
            }

        & svg {
            width: 1.125rem;
            height: 1.125rem;
            }
        }


/** ------------------------------
    Modifiers
    ------------------------------ */
.social-list--rounded {

    & a {
        display: flex;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, .2);

        &:hover {
            background-color: var(--brand-secondary);
            color: #fff !important;
            }
        }

    & svg {
        margin: auto;
        width: 1rem;
        height: 1rem;
        }

    }

.social-list--align-top {
    align-items: flex-start;
    }


/** ------------------------------
    Wrapped list
    ------------------------------ */
.social-list-wrap {
    display: flex;
    align-item: center;

    & > * {
        margin: 0;
        }
    }
    .social-list-wrap__title {
        margin-right: .5em;
        }
